
function Credit (props) {

    return (
        <div style={{ paddingBottom: '100px', width: '20%' }}>
            
        </div>
    )
}

export default Credit
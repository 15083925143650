import React, { useState, lazy, Suspense } from 'react'
import Switch from "react-switch"
import { Icon } from 'semantic-ui-react'
import StyledContentLoader from 'styled-content-loader'
import { LIGHT, DARK, COLOR } from './constants/theme'
import { EXPERIENCES} from './assets/data'
import Credit from './components/credit'

const Intro = lazy(() => import('./components/intro'))
const Experience = lazy(() => import('./components/experience'))
const Project = lazy(() => import('./components/project'))



function App() {
  
  const [isDark, setIsDark] = useState(false)
  const theme = isDark ? DARK : LIGHT
  document.body.style = `background: ${theme.BACKGROUND}`

  const handleChange = () => {
    setIsDark(!isDark)
  }

  const ContentLoader = () => {
    return (
      <StyledContentLoader backgroundColor={theme.SIDE_BAR_BACKGROUND} foreGroundColor={theme.CARD_BACKGROUND}>
        <div style={{ width: '800px', height: '200px', marginTop: '10px', marginBottom: '10px', borderRadius: '8px'}}></div>
      </StyledContentLoader>
    )
  }

  return (
    <div>  
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '10vh'}}>
        <Icon style={{color: theme.TEXT, marginLeft: '3px'}} name='sun' size='big'/>
        <Switch
          onChange={handleChange}
          checked={isDark}
          onColor={COLOR.GREEN}
          onHandleColor={COLOR.WHITE}
          handleDiameter={25}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={25}
          width={100}
        />
        <Icon style={{color: theme.TEXT, marginLeft: '3px'}} name='moon' size='big' />
      </div>
      <Suspense fallback={<ContentLoader />}>
        <Intro theme={theme} />
      </Suspense>

      <Suspense fallback={<ContentLoader />}>
        <Experience theme={theme} experiences={EXPERIENCES} header={'Experience'} />
      </Suspense>


      <Suspense fallback={<ContentLoader />}>
        <Project theme={theme} />
      </Suspense>

      <Credit theme={theme} />

    </div>
  )
}

export default App



import Douglas from './Douglas.png'
import RBC from './RBC.png'
import UBC from './ubc-logo.png'
import orbis from './orbis.png'
import orbisDark from './orbisDark.png'
import bolt from './bolt.png'

// npm run build && firebase deploy

export const TYPING_TEXT = [
    {
        text: 'hello :) i\'m saif',
        backspaceCount: 5
    },
    {
        text: ' a 4th year CS student @UBC',
        backspaceCount: 26
    },
    {
        text: ' an aspiring software engineer',
        backspaceCount: 25
    }
]

export const BIO_INFO = {
    'name': 'Saif Karnawi',
    'bio': 'ubc cs\' 25 | seeking 2024 software engineering internships',
    'location': 'Vancouver, British Columbia, Canada',
    'contact': {
        'email': 'saifkarnawi2000@gmail.com',
        'github': 'saif-karnawi',
        'linkedin': 'https://www.linkedin.com/in/saif-karnawi/',
        'instagram': 'https://www.instagram.com/sfkrnwi/'
    },
    'hobbies': [
        'creating apps',
        'spikeball',
        'hiking',
        'ultimate frisbee',
        'videography/photography',
        'volleyball'
    ],
    'interests': [
        'AI',
        'Stocks',
        'Machine Learning' 
    ],
    
}

export const CONTACT = [
    {
        iconName: 'github square',
        link: 'https://github.com/saif-karnawi'
    },
    {
        iconName: 'linkedin',
        link: 'https://www.linkedin.com/in/saif-karnawi/'
    },
    {
        iconName: 'instagram',
        link: 'https://www.instagram.com/sfkrnwi/'
    },
    {
        iconName: 'envelope',
        link: 'mailto:saifkarnawi2000@gmail.com'
    }
]

export const EXPERIENCES = [
    
    {
        title: 'Software Engineer Intern',
        company: 'Orbis Investments',
        time: 'Jan 2023 - Aug 2023',
        location: 'Vancouver, BC, Canada',
        description: [
            'Took an active role in developing and planning the transact online page used by clients worldwide, covering the complete transaction workflow from user interface interactions to the moment the transaction is sent to CitiBank',
            'Migrated web pages from Angular 7 to React with a focus on elevating code readability and implementing a well-organized structure for improved maintainability',
            'Developing new full-stack features, fixing front-end bugs, updating frameworks and packages, and writing integration, regression, and unit tests for client facing websites',
            'Developing full-stack to create an Angular admin tools website for stock analysts and portfolio managers with $30 billion of assets under management',
            'Created and updated multiple endpoints for various C# services',
            'Implemented back-end caching and reduced the wait times for API requests'
        ],
        light: orbis,
        dark: orbisDark,
        tags: []
    },
    {
        title: 'Software Engineer Intern',
        company: 'RBC',
        time: 'Sep 2022 - Dec 2022',
        location: 'Toronto, Ontario, Canada',
        description: [
            'Worked with HTML and CSS to develop fully automated tests using Python and Selenium that validate expected behaviors of numerous functionalities of RBC websites',
            'Set up test automation for different browsers and languages using RBC internal testing tools and connecting them to Jenkins nodes',
            'Presented the selenium automation project to 20+ stakeholders and led the discussion about CI/CD'
        ],
        light: RBC,
        dark: RBC,
        tags: []
    },
    
    {
    
        title: 'Software Engineer',
        company: 'Lightning Bots',
        time: '',
        location: 'Vancouver, British Columbia, Canada',
        description: [
            'Implementing path planning algorithms for the robots using C++ and Python'
        ],
        light: bolt,
        dark: bolt,
        tags: []

    },{
    
        title: 'Tri-mentoring - Program Participant',
        company: 'UBC Tri-mentoring',
        time: 'Sep 2022 - Dec 2022',
        location: 'Vancouver, British Columbia, Canada',
        description: [
            'Competitive entry based on average, this program matches two ubc students with a mentor in the tech industry. '
        ],
        light: UBC,
        dark: UBC,
        tags: []

    }

    ,{
    
        title: 'Club President & Founder',
        company: 'Douglas College',
        time: 'Oct 2019 - May 2021',
        location: 'Vancouver, British Columbia, Canada',
        description: [
            'Founder and president of the ultimate frisbee club',
            'Directed and supervised events such as social, sponsored, and staff'
        ],
        light: Douglas,
        dark: Douglas,
        tags: []

    }


    // {
    //     title: 'Software Enginner Intern',
    //     company: 'Example',
    //     time: 'Example',
    //     location: 'Example',
    //     description: [
    //         'Example',
    //         'Example'
    //     ],
    //     light: Example,
    //     dark: ExampleDark, //import ExampleDark from './ExampleDark.png'
    //     tags: ['Example', 'Example']
    // }
]


export const PROJECTS = [
    {
        name: 'ShareIt',
        icon: null,
        subHeader: 'web app',
        description: 'I wanted to create a social media website similar to instagram. This website allows users to share images, like, dislike, and comment. I used MongoDb for data persistence, React for front-end, Node and Express for backend. Deployed using Heroku.',
        tags: ['React', 'Express','Redux', 'MongoDb', 'Node', 'Heroku', 'JSON', 'NoSQL'],
        links: {
            'github': 'https://github.com/saif-karnawi/ShareIt---React-Redux-Node-Express-MongoDB'
        }
    },

    {
        name: 'Ballance',
        icon: null,
        subHeader: 'game',
        description: '3D Game where players are able to control a ball through a series of obstacles.',
        tags: ['C#','Unity'],
        links: {
            'github': 'https://github.com/saif-karnawi/Ballance-UnityandCSharpGame'
        }
    },

    {
        name: 'Depth & Breadth First Search Visualizer',
        icon: null,
        subHeader: 'application // data visulization',
        description: 'Written in C++, this application incorporates visual algorithms that fill GIF images in depth or breadth first search. Prior to the fill algorithms, the application renders the images by working with image and pixel classes.',
        tags: ['C++'],
        links: {
            'github': 'https://github.com/saif-karnawi/Depth-and-Breadth-First-Search-Algorithms'
           
        }
    },
    {
        name: 'AirSearch',
        icon: null,
        subHeader: 'application',
        description: 'AirSearch allows users to search for their flight using flight number, airline, or by airport. Currently working on connecting this application to the FlightXML API for real-time data.',
        tags: ['FlightXML API', 'Azure', 'Java', 'XML', 'JavaFX', 'Swing', 'MySQL'],
        links: {
            'github': 'https://github.com/saif-karnawi/AirSearch'
        }
    },
    {
        name: 'Multithreading in C',
        icon: null,
        subHeader: 'application',
        description: 'Away from all the visual projects, this one might not be as exciting with a quick look. High level multithreading in C to solve multiple problems.',
        tags: ['C'],
        links: {
            'github': 'https://github.com/dchen150/NBAFavoritePlayers'
        }
    },
    {
        name: 'Two Dimension Doubly LinkedLists ',
        icon: null,
        subHeader: 'application',
        description: 'This program allows you to create a two dimension doubly linkedList from any picture. Every pixel will become a node. From there, you can choose to carve out nodes depending on the mode you choose. After a node is carved from each row or each column, the picture will be rendered. ',
        tags: ['C++'],
        links: {
            'github': 'https://github.com/saif-karnawi/2D-Doubly-Linked-List'
        }
    },
    {
        name: 'Spotify-Recommend',
        icon: 'spinner',
        subHeader: 'web app',
        description: 'In progress. Users will be able to input three songs and three artists. Based on their input, the web app will generate and recommend a playlist of 10 songs that were released within the last month using the spotify API.',
        tags: ['Spotify Api', 'Javascript'],
        links: {
            
        }
    },
    {
        name: 'Star Defense',
        icon: 'spinner',
        subHeader: 'game',
        description: 'In progress. Star defense is an online game with multiple levels and backgrounds. Using the keyboard arrows, the user will be able to move the aircraft and shoot stars a variety of strength, speed, and size.',
        tags: ['C++', 'Unity'],
        links: {
            
        }
    },
    {

        name: 'Personal Portfolio Website',
        icon: null,
        subHeader: 'website',
        description: 'The website you are currently viewing :) 10+ React libraries for a modern look. Deployed using Firebase',
        tags: ['Firebase', 'JavaScript', 'HTML', 'CSS', 'React'],
        links: {
 
        }
    }
]